import {
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  SimpleGrid,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { Field, Formik } from "formik";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import CustomInputField from "../../utilities/components/CustomInputField";
import { useAuth } from "../../utilities/hooks/auth-hook";

import Editor from "../components/Editor";
const validationSchema = Yup.object({
  agendaTitle: Yup.string().required("Agenda title is required"),
  amharicAgendaTitle: Yup.string(),
  agendaDescription: Yup.string().required("Agenda description is required"),
  amharicAgendaDescription: Yup.string(),
  vote_limit: Yup.number().when("isNominee", {
    is: true,
    then: Yup.number().required("Vote limit is required"),
  }),
  nominee_only_from_attendant: Yup.boolean().when("isNominee", {
    is: true,
    then: Yup.boolean().required(" required"),
  }),

  template: Yup.object(),
});

const AddNewAgenda = () => {
  const [template, setTemplate] = React.useState();
  const { token } = useAuth();
  const [agenda, setAgenda] = React.useState({
    agendaTitle: "",
    amharicAgendaTitle: "",
    agendaDescription: "",
    amharicAgendaDescription: "",
    isNominee: false,
  });
  const toast = useToast();
  const [agendas, setAgendas] = React.useState([]);
  const { state } = useLocation();
  const params = useParams();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const agendaData = useQuery(
    "agendaData",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}agendas/${params?.meetingId}`,

        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  React.useEffect(() => {
    if (agendaData?.data?.data) {
      setAgendas(agendaData?.data?.data?.data);
    }
  }, [agendaData?.data?.data]);
  const createAgenda = useMutation(
    async (data) => {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}agendas`, data, {
        headers,
      });
    },
    {
      onSuccess: () => {
        agendaData.refetch();

        toast({
          title: "Agenda created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        agendaData.refetch();
      },
    }
  );

  const deleteAgenda = useMutation(
    async (id) => {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}agendas/${id}`, {
        headers,
      });
    },
    {
      onSuccess: () => {
        agendaData.refetch();
        toast({
          title: "Agenda deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (err) => {
        toast({
          title: `${err?.response?.data?.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const getAgendaTemplates = useQuery(
    ["agenda-template"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}agenda-templates`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  return (
    <div>
      <Formik
        initialValues={{
          agendaTitle: "",
          agendaDescription: "",
          amharicAgendaTitle: "",
          amharicAgendaDescription: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          createAgenda.mutate({
            title: values.agendaTitle,
            title_amharic: values.amharicAgendaTitle,
            description: values.agendaDescription,
            description_amharic: values.amharicAgendaDescription,
            is_nominee_vote: agenda.isNominee,
            meeting_id: params?.meetingId,
            vote_limit: values.vote_limit,
            nominee_only_from_attendant: values.nominee_only_from_attendant,
            exclude_influential_shareholders: agenda.excludeInfluential,
          });
          actions.setSubmitting(false);
          actions.resetForm();
        }}
      >
        {(props) => {
          return (
            <HStack
              sx={{
                display: "flex",
                width: "95%",
                justifyContent: "space-around",
              }}
              spacing={4}
              mb={4}
              ml={6}
            >
              <Center
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Text
                  fontSize="2xl"
                  color={"#E82481"}
                  fontWeight="bold"
                  textAlign="center"
                  mb={4}
                >
                  {state?.meetingTittle}
                </Text>
                <Box bg="white" p={6} w={"90%"} alignItems="center">
                  <VStack
                    my={10}
                    align={"flex-start"}
                    sx={{
                      display: "flex",
                      // width: "30%",
                    }}
                  >
                    <Typography align="left" variant="h6">
                      Select Template
                    </Typography>
                    <Select
                      name="template"
                      placeholder="Select option"
                      getOptionLabel={(option) => option.title}
                      getOptionValue={(option) => option.value}
                      onChange={(selectedOption) => {
                        props.setFieldValue(
                          "agendaTitle",
                          selectedOption?.title
                        );

                        props.setFieldValue(
                          "agendaDescription",
                          selectedOption?.description
                        );
                        props.setFieldValue(
                          "amharicAgendaTitle",
                          selectedOption?.title_amharic
                        );
                        props.setFieldValue(
                          "amharicAgendaDescription",
                          selectedOption?.description_amharic
                        );
                      }}
                      value={template?.value}
                      isLoading={getAgendaTemplates?.isFetching}
                      options={getAgendaTemplates?.data?.data?.data}
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          backgroundColor: "white",
                          borderRadius: "20px",
                        }),

                        valueContainer: (provided) => ({
                          ...provided,
                          width: "100%",
                          height: "100%",
                          border: "none",
                          zIndex: 999,
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: "100%",
                          height: "100%",
                        }),
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "20px",
                          backgroundColor: "#EAEAEA",
                          width: "100%",
                          background: "#EAEAEA",
                          padding: "0px 10px",
                          border: "none",

                          minHeight: "50px",

                          "&:hover": {
                            borderColor: "none",
                          },
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          backgroundColor: state.isSelected
                            ? "#0077FF"
                            : "transparent",
                          color: state.isSelected ? "white" : "#000",
                          zIndex: 999,

                          height: "100%",
                          "&:hover": {
                            backgroundColor: "#0077FF",
                            color: "white",
                          },
                        }),
                      }}
                    />
                  </VStack>
                  <SimpleGrid
                    as={"form"}
                    gap={4}
                    columns={2}
                    align="center"
                    justifyContent="center"
                    onSubmit={props.handleSubmit}
                    // border={"1px solid #EAEAEA"}
                    w={"100%"}
                  >
                    {/* {agenda?.isNominee ? null : ( */}
                    <Field name="agendaTitle">
                      {({ field, form }) => (
                        <CustomInputField
                          field={field}
                          form={form}
                          label="Title"
                          name="agendaTitle"
                          title={"Agenda Title"}
                          touched={form.touched.agendaTitle}
                          error={form.errors.agendaTitle}
                          placeholder={"Enter  Title of the agenda"}
                        />
                      )}
                    </Field>
                    <Field name="amharicAgendaTitle">
                      {({ field, form }) => (
                        <CustomInputField
                          field={field}
                          form={form}
                          label="የአጀንዳ ርዕስ"
                          name="amharicAgendaTitle"
                          title={"የአጀንዳ ርዕስ"}
                          touched={form.touched.amharicAgendaTitle}
                          error={form.errors.amharicAgendaTitle}
                          placeholder={"የአጀንዳውን ርዕስ አስገባ"}
                        />
                      )}
                    </Field>

                    <FormControl isInvalid={!!props.errors.note}>
                      <FormLabel
                        sx={{
                          fontSize: "16px",
                          // fontWeight: "bold",
                          // color: "#B51D64",
                        }}
                        htmlFor="note"
                      >
                        Agenda Description
                      </FormLabel>
                      <Field name="agendaDescription">
                        {({ field, form }) => (
                          <Editor
                            {...field}
                            {...form}
                            value={props.values.agendaDescription}
                            setValue={(value) =>
                              form.setFieldValue("agendaDescription", value)
                            }
                          />
                        )}
                      </Field>
                      <FormErrorMessage>{props.errors.note}</FormErrorMessage>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        !!props.errors.note && props.touched.amharic_note
                      }
                    >
                      <FormLabel
                        sx={{
                          // fontSize: "18px",
                          // fontWeight: "bold",
                          // color: "#B51D64",
                          fontSize: "16px",
                        }}
                        htmlFor="note"
                      >
                        የአጀንዳ መግለጫ
                      </FormLabel>
                      <Field name="amharicAgendaDescription">
                        {({ field, form }) => (
                          <Editor
                            {...field}
                            {...form}
                            value={props.values.amharicAgendaDescription}
                            setValue={(value) =>
                              form.setFieldValue(
                                "amharicAgendaDescription",
                                value
                              )
                            }
                          />
                        )}
                      </Field>
                      <FormErrorMessage>
                        {props.errors.amharic_note}
                      </FormErrorMessage>
                    </FormControl>
                    <VStack
                      // shadow={"md"}
                      p={2}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "100%",
                        gap: "1rem",
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: "gray.900",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          // padding: "2rem",
                          borderColor: "green.500",

                          _focus: {
                            boxShadow: "none",
                          },
                        }}
                        onChange={(e) => {
                          setAgenda((prevState) => ({
                            ...prevState,
                            isNominee: e.target.checked,
                          }));
                        }}
                        value={agenda.isNominee}
                        name="radio"
                        colorScheme="green"
                        my={".5rem"}
                      >
                        Is Nominee Vote
                      </Checkbox>
                      {agenda?.isNominee && (
                        <Field name="vote_limit">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              label="Vote Limit"
                              name="vote_limit"
                              title={"Vote Limit"}
                              placeholder={"Enter vote limit"}
                              error={form.errors.vote_limit}
                              touched={form.touched.vote_limit}
                              extra={"number"}
                              type={"number"}
                            />
                          )}
                        </Field>
                      )}
                    </VStack>

                    <VStack
                      // shadow={"md"}
                      p={2}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      {agenda?.isNominee && (
                        <Field name="nominee_only_from_attendant">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              label="Is nominee only from attendant"
                              name="nominee_only_from_attendant"
                              title={"Only from attendant"}
                              error={form.errors.nominee_only_from_attendant}
                              touched={form.touched.nominee_only_from_attendant}
                              extra={"checkbox"}
                            />
                          )}
                        </Field>
                      )}
                      <Checkbox
                        sx={{
                          color: "gray.900",
                          fontSize: "1.5rem",
                          fontWeight: "bold",
                          paddingY: !agenda?.isNominee ? "0" : "2rem",
                          borderColor: "green.500",

                          _focus: {
                            boxShadow: "none",
                          },
                        }}
                        onChange={(e) => {
                          setAgenda((prevState) => ({
                            ...prevState,
                            excludeInfluential: e.target.checked,
                          }));
                        }}
                        value={agenda.excludeInfluential}
                        name="radio"
                        colorScheme="green"
                        my={".5rem"}
                      >
                        Exclude Influential
                      </Checkbox>
                    </VStack>

                    {/* <HStack
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {agenda?.isNominee && (
                        <Field name="vote_limit">
                          {({ field, form }) => (
                            <CustomInputField
                              field={field}
                              form={form}
                              label="Vote Limit"
                              name="vote_limit"
                              title={"Vote Limit"}
                              placeholder={"Enter vote limit"}
                              error={form.errors.vote_limit}
                              touched={form.touched.vote_limit}
                              extra={"number"}
                            />
                          )}
                        </Field>
                      )}
                    </HStack> */}
                    {/* <Box w="100%" h="1px" bg="gray.200" visibility={false} /> */}

                    <Button
                      type="submit"
                      colorScheme="purple"
                      // disable if one of the field is empty
                      disabled={props.isSubmitting || !props.isValid}
                      width="full"
                      mt={8}
                    >
                      Create Agenda
                    </Button>
                  </SimpleGrid>
                </Box>
              </Center>
            </HStack>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddNewAgenda;
