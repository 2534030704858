import {
  Box,
  Button,
  HStack,
  Progress,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React, { useState } from "react";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Loader from "react-spinners/BarLoader";
import { useAuth } from "../../../../utilities/hooks/auth-hook";
import AddMeetingType from "./AddMeetingType";
import EditMeetingType from "./Edit";

export const MeetingType = () => {
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editId, setEditId] = useState(null);
  const toast = useToast();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const deleteMeetingType = useMutation(
    async (type_id) =>
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + `meeting-types/${type_id?.id}`,
        {
          headers,
        }
      ),
    {
      retry: false,
    }
  );
  const meetingType = useQuery(
    ["MeetingType"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}meeting-types`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  React.useEffect(() => {
    if (meetingType?.data?.data) {
      setData(meetingType?.data?.data?.data);
    }
  }, [meetingType?.data?.data]);
  const queryClient = useQueryClient();
  const [columns, setColumns] = React.useState([
    { title: "Name", field: "name" },
    {
      title: "Minimum Attendant",
      field: "minimum_attendant",
    },
    {
      title: "Can start",
      field: "start_from_minimum_attendant",
      render: (rowData) => {
        return rowData?.start_from_minimum_attendant === "1" ? "Yes" : "No";
      },
    },
    {
      title: "Description",
      field: "description",
    },

    {
      render: (rowData) => {
        return (
          <HStack>
            <Button
              size={"sm"}
              onClick={() => {
                setEditId(rowData?.id);
              }}
            >
              <AiFillEdit />
            </Button>
            <Box>
              <Button
                size={"sm"}
                border="1px solid #006ddb"
                onClick={() => {
                  deleteMeetingType.mutate(
                    {
                      id: rowData?.id,
                    },
                    {
                      onSuccess: (responseData) => {
                        toast({
                          title: " deleted successfully",
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                        });
                        queryClient.invalidateQueries("MeetingType");
                      },
                      onError: (responseData) => {
                        toast({
                          title:
                            responseData?.response?.data?.message ||
                            "Not  deleted!",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                      },
                    }
                  );
                }}
              >
                <AiOutlineDelete />
              </Button>
            </Box>
            {deleteMeetingType?.isLoading && <Loader />}
          </HStack>
        );
      },
    },
  ]);
  const onCancel = () => {
    setEditId(false);
    onClose();
  };
  return (
    <>
      <AddMeetingType isOpen={isOpen} onClose={onCancel} />
      <EditMeetingType editId={editId} isOpen={!!editId} onClose={onCancel} />

      <Box w={"100%"}>
        <Button my={2} onClick={onOpen}>
          Add Meeting Type
        </Button>
        <MaterialTable
          isLoading={meetingType.isLoading}
          components={{
            OverlayLoading: (props) => (
              <Progress size="md" colorScheme="pink" isIndeterminate />
            ),
          }}
          title="Meeting Type"
          columns={columns}
          data={data}
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: "#E82481",
              color: "#FFF",
            },
          }}
        />
      </Box>
    </>
  );
};
