import React, { useState } from "react";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Divider,
  Heading,
  IconButton,
  Progress,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import Select from "react-select";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import { NomineeCard } from "../Voting/NomineeCard";
import { useLocation, useParams } from "react-router-dom";
import { customStyles } from "./Attendance";
import { DeleteIcon } from "@chakra-ui/icons";
import MaterialTable from "material-table";
import moment from "moment";

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      valueContainer: (provided, state) => ({
        ...provided,
        background: "#fff",
        borderColor: "#9e9e9e",
        minHeight: "5indicatorsContainer0px",
        height: "80px",
        boxShadow: state.isFocused ? null : null,
        zIndex: "14343",
      }),

      backgroundColor: isDisabled ? "red" : "whitesmoke",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "5px",
      marginBottom: "5px",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#000" : "#000"),
      },
    };
  },
};

export const AddNomine = () => {
  const { token } = useAuth();
  const [data, setData] = useState([]);
  const [selectedShareholder, setSelectedShareholder] = useState();
  const toast = useToast();
  const params = useParams();
  const { state } = useLocation();

  const handleChange = (e) => {
    setSelectedShareholder(e);
  };

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getStakeHolder = useQuery(
    "getStakeHolder",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
        params: {
          agenda_id: params?.agendaId,
        },
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!token,
      onSuccess: (res) => {
        setData(res.data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const createNominee = useMutation(
    "createNominee",
    async (data) =>
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}nominees`, data, {
        headers,
      }),
    {
      onSuccess: (res) => {
        toast({
          title: "Nominee Created",
          description: "Nominee Created Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        }) &&
          setSelectedShareholder(null) &&
          setData([]) &&
          getNominees.refetch();
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `${err?.response?.data?.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  const deleteNominee = useMutation(
    "deleteNominee",
    async (id) =>
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}nominees/${id}`, {
        headers,
      }),
    {
      onSuccess: (res) => {
        toast({
          title: "Nominee Deleted",
          description: "Nominee Deleted Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        }) &&
          getNominees.refetch() &&
          setSelectedShareholder(null) &&
          setData([]) &&
          getStakeHolder.refetch();
      },
      onError: (err) => {
        toast({
          title: "Error",
          description: `${err?.response?.data?.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );

  const getNominees = useQuery(
    `getNominees ${params?.meetingTitle}`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}nominees/${params?.agendaId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token && !!state?.meetingId,
      onSuccess: (res) => {},
    }
  );
  return (
    <VStack>
      <Heading
        sx={{
          textAlign: "start",
        }}
      >
        Voting Management{" "}
      </Heading>
      <Center
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Text
          sx={{
            fontSize: "xl",
            fontWeight: "bold",
            color: "gray.900",
            my: "2rem",
            textAlign: "start",
          }}
        >
          Create Nominee
        </Text>

        <Wrap spacing={20}>
          {/* <WrapItem>
            <Checkbox
              fontSize={"lg"}
              w={"20vh"}
              colorScheme="pink"
              onChange={(e) => {
                setIsAttendant(e.target.checked);
              }}
              checked={isAttendant}
              defaultChecked={isAttendant}
              // iconColor="green.500"
              // borderColor="gray.40 0"
              borderRadius="sm"
              sx={{
                fontSize: "xl",
                fontWeight: "bold",
                color: "gray.900",
                // marginBottom: "2rem",
                textTransform: "capitalize",
                borderColor: "pink.500",
                boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                p: "1rem",

                _checked: {},
                _label: {
                  fontSize: "lg",
                  fontWeight: "bold",
                  color: "gray.900",
                  textTransform: "capitalize",
                },
              }}
            >
              Is Attendant
            </Checkbox>
          </WrapItem> */}
          <WrapItem>
            <Select
              w={"20vh"}
              styles={customStyles}
              placeholder={"Select Nominee"}
              isDisabled={
                getStakeHolder.isError ||
                getStakeHolder.getStakeHolder?.isLoading
              }
              isLoading={getStakeHolder?.isLoading}
              options={data}
              getOptionLabel={(option) =>
                `${
                  option?.serial_number ? `( ${option?.serial_number})` : ""
                } ${option?.name}  `
              }
              value={selectedShareholder}
              getOptionValue={(option) => option?.id}
              name="data"
              onChange={handleChange}
            />
          </WrapItem>
        </Wrap>

        {selectedShareholder && (
          <>
            <Text
              sx={{
                fontSize: "xl",
                fontWeight: "bold",
                color: "gray.900",
                marginBottom: "2rem",
                textTransform: "capitalize",
              }}
            >
              {" "}
              {/* {selectedMeeting?.title} */}
            </Text>
            <NomineeCard
              name={selectedShareholder?.name}
              capital={Number(
                selectedShareholder?.current_capital
              )?.toLocaleString()}
              boughtShareCount={Number(
                selectedShareholder?.bought_shares_count
              )?.toLocaleString()}
              numberOfShare={Number(
                selectedShareholder?.no_of_shares
              )?.toLocaleString()}
              dividend={Number(selectedShareholder?.dividend)?.toLocaleString()}
              soldShareCount={Number(
                selectedShareholder?.sold_shares_count
              )?.toLocaleString()}
              disabled={
                createNominee.isLoading ||
                getStakeHolder.isLoading ||
                !!!selectedShareholder
              }
              onClick={() => {
                createNominee.mutate({
                  stakeholder_id: selectedShareholder?.id,
                  agenda_id: params?.agendaId,
                });
              }}
            />
          </>
        )}
      </Center>
      <MaterialTable
        isLoading={getNominees.isLoading}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <Text
                sx={{
                  fontSize: "md",
                  fontWeight: "bold",
                  color: "gray.500",
                  textAlign: "center",
                }}
              >
                No Nominee Found
              </Text>
            ),
          },
        }}
        style={{
          zIndex: "0",
          margin: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
          width: "80%",
        }}
        options={{
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          rowStyle: {
            color: "#000",
            backgroundColor: "#FFF",
          },
          searchFieldStyle: {
            backgroundColor: "#FFF",
            color: "#000",
          },

          padding: "default",
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Agenda List",
        }}
        title="Nominee List"
        columns={[
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Phone",
            field: "phone",
          },
          {
            title: "Created At",
            field: "created_at",
            render: (rowData) => (
              <Text>{moment(rowData.created_at).format("lll")}</Text>
            ),
          },
          {
            title: "Delete",
            field: "action",
            render: (rowData) => (
              <IconButton
                onClick={() => {
                  deleteNominee.mutate(rowData.id);
                }}
                colorScheme="red"
                aria-label="Call Segun"
                size="sm"
                icon={<DeleteIcon />}
              />
            ),
          },
        ]}
        data={getNominees?.data?.data?.data}
      />
    </VStack>
  );
};

export default AddNomine;
