import React from "react";
import {
  Box,
  Center,
  Heading,
  Flex,
  Text,
  Wrap,
  WrapItem,
  Button,
} from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { AddIcon } from "@chakra-ui/icons";
import { useToast } from "@chakra-ui/react";
import { AttendantTable } from "./AttendantTable";
import { useQueryClient } from "react-query";

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    zIndex: "2323",
    width: "550px",
    height: "50px",
    borderColor: state.isFocused ? "#805AD5" : "#E2E8F0",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#805AD5",
    },
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#9e9e9e",
    minHeight: "5indicatorsContainer0px",
    boxShadow: state.isFocused ? null : null,
    zIndex: "14343",
    width: "350px",
  }),
};
export const Attendance = () => {
  const { state } = useLocation();
  const queryClient = useQueryClient();

  const params = useParams();
  const { token } = useAuth();
  const toast = useToast();
  const [refetch, setRefetch] = React.useState(false);

  const [shareholder, setShareholder] = React.useState(null);
  const [proxy, setProxy] = React.useState(null);
  const handleOption1Change = (selectedOption) => {
    setShareholder(selectedOption);
    setProxy(null);
  };
  const handleOption2Change = (selectedOption) => {
    setProxy(selectedOption);
    setShareholder(null);
  };

  // console.log(params);
  // console.log(state);

  const isOption1Disabled = proxy !== null;
  const isOption2Disabled = shareholder !== null;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const attendeeData = useQuery(
    "attendeesData",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}attendances/${params?.meetingId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  const createAttendance = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}attendances`,
        newData,
        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("attendeesData");
        toast({
          title: "Attendance created.",
          description: "Created new attendance successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        setRefetch(true);
        setShareholder(null);
        setProxy(null);
      },
    },
    {
      retry: false,
    }
  );
  return (
    <Box>
      <Text
        fontSize="2xl"
        marginLeft={6}
        fontWeight="bold"
        textAlign="start"
        my={4}
      >
        Add New Attendance
      </Text>
      {/* <Text>{params?.meetingId}</Text> */}
      <Center
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          fontSize="2xl"
          color={"#E82481"}
          fontWeight="bold"
          textAlign="center"
          mb={4}
        >
          {state?.meetingTittle}
        </Text>
        <Wrap
          spacing="30px"
          justify="space-between"
          width="90%"
          mt={6}
          direction={"row"}
        >
          <WrapItem
            as={Flex}
            direction={"column"}
            align="center"
            justify="center"
          >
            <Heading size="md" my={4}>
              Select Shareholder
            </Heading>

            <Select
              styles={customStyles}
              placeholder="Select Shareholder"
              options={attendeeData?.data?.data?.data?.stakeholders}
              getOptionLabel={(option) =>
                `${
                  option?.serial_number ? `( ${option?.serial_number})` : ""
                } ${option?.name}  `
              }
              getOptionValue={(option) => option.id}
              onChange={handleOption1Change}
              value={shareholder}
              isDisabled={isOption1Disabled}
            />
          </WrapItem>
          <WrapItem
            as={Flex}
            direction={"column"}
            align="center"
            justify="center"
          >
            <Heading size="md" my={4}>
              Select Proxy
            </Heading>

            <Select
              styles={customStyles}
              placeholder="Select Proxy"
              options={attendeeData?.data?.data?.data?.proxies}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              onChange={handleOption2Change}
              value={proxy}
              isDisabled={isOption2Disabled}
            />
          </WrapItem>
        </Wrap>
        <Button
          my={"20px"}
          ml={"10px"}
          mr={"20px"}
          leftIcon={<AddIcon />}
          bg={"#B76A1D"}
          color={"white"}
          variant="solid"
          _hover={{ bg: "#B76A1D" }}
          disabled={createAttendance.isLoading}
          onClick={() => {
            createAttendance.mutate({
              meeting_id: params?.meetingId,
              proxy_id: proxy?.id,
              stakeholder_id: shareholder?.id,
            });
          }}
        >
          {" "}
          Add Attendance{" "}
        </Button>
      </Center>
      <AttendantTable refetch={refetch} meetingId={params.meetingId} />
    </Box>
  );
};
