import { Box } from "@chakra-ui/layout";
import { Tag, TagLabel } from "@chakra-ui/react";
import { Progress } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useAuth } from "../../utilities/hooks/auth-hook";

export const Shareholders = () => {
  const { token } = useAuth();
  const [agendas, setAgendas] = React.useState([]);

  const [column] = React.useState([
    {
      title: "Serial No",
      field: "serial_number",
      cellStyle: {
        width: 140,
        minWidth: 100,
      },
    },
    {
      title: "ስም",
      field: "name_amharic",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 300,
        minWidth: 300,
      },
    },
    {
      title: "Name",
      field: "name",
      cellStyle: {
        width: 200,
        minWidth: 200,
      },
      headerStyle: {
        width: 300,
        minWidth: 300,
      },
    },

    {
      title: "Phone",
      field: "phone",
    },
    // {
    //   title: "Email",
    //   field: "email",
    //   render: (rowData) => {
    //     return rowData?.email ? rowData?.email : "N/A";
    //   },
    // },
    // {
    //   title: "Dividend",
    //   field: "dividend",
    // },
    {
      title: "Current Capital",
      field: "current_capital",
      render: (rowData) => {
        return rowData?.current_capital.toLocaleString();
      },
    },
    {
      title: "Kifle Ketema",
      field: "information.kifle_ketema",
    },
    {
      title: "Wereda",
      field: "information.wereda",
    },
    {
      title: "House No",
      field: "information.house_no",
    },
    {
      title: "Status",
      field: "status",
      render: (rowData) => {
        return rowData?.status === "1" ? "Active" : "Inactive";
      },
    },

    {
      title: "Type",
      field: "type",
      //render chip for type
      render: (rowData) => {
        return (
          <Tag
            size={"sm"}
            variant="solid"
            colorScheme={rowData?.type === "Normal" ? "green" : "blue"}
            padding={"0.5rem"}
          >
            <TagLabel>{rowData?.type}</TagLabel>
          </Tag>
        );
      },
    },
    {
      title: "No of Shares",
      field: "no_of_shares",
    },
    // {
    //   title: "Total Share",
    //   field: "total_share",
    // },
  ]);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const agendaData = useQuery(
    "stakeholders",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}stakeholders`,

        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  React.useEffect(() => {
    if (agendaData?.data?.data) {
      setAgendas(agendaData?.data?.data?.data);
    }
  }, [agendaData?.data?.data]);

  return (
    <Box>
      <MaterialTable
        isLoading={agendaData.isLoading}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        style={{
          margin: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        options={{
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          rowStyle: {
            color: "#000",
            backgroundColor: "#FFF",
          },
          searchFieldStyle: {
            backgroundColor: "#FFF",
            color: "#000",
          },

          padding: "default",
          actionsColumnIndex: -1,
          exportButton: false,
          exportAllData: false,
          exportFileName: "Shareholders List",
          pageSize: 10,
          pageSizeOptions: [10, 15, 25, 50, 100],
        }}
        title="Shareholders List"
        columns={column}
        data={agendas}
      />
    </Box>
  );
};
