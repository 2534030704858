import React, { useState } from "react";
import { Box, Heading, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import MaterialTable from "material-table";
import { customStyles } from "../Meeting/Attendance";
import Select from "react-select";

export const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,

      valueContainer: (provided, state) => ({
        ...provided,
        background: "#fff",
        borderColor: "#9e9e9e",
        minHeight: "50px",
        height: "80px",
        boxShadow: state.isFocused ? null : null,
        zIndex: "14343",
      }),

      backgroundColor: isDisabled ? "red" : "whitesmoke",
      color: "#000",
      cursor: isDisabled ? "not-allowed" : "default",
      fontWeight: "bold",
      fontSize: "14px",
      borderRadius: "5px",
      marginBottom: "5px",
      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? "#000" : "#000"),
      },
    };
  },
};

export const VoteReport = () => {
  const { token } = useAuth();
  const [nomineeData, setNomineeData] = useState([]);
  const [meeting, selectMeeting] = useState({});
  const [agenda, setAgenda] = useState({});

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const getMeetings = useQuery(
    ["meetingDataForCardDataReport"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meetings`,

        {
          headers,
        }
      ),

    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!token,
      onSuccess: (data) => {
        selectMeeting(data?.data?.data?.[0]);
      },
    }
  );

  const getMeetingData = useQuery(
    [`getNominees-vote-report`, meeting?.id, agenda?.id],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meeting-dashboard?meeting_id=${meeting?.id}&agenda_id=${agenda?.id}`
      ),

    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: true,
      enabled: !!meeting?.meetingId && !!agenda?.id,

      refetchOnReconnect: true,
      onSuccess: (data) => {
        setNomineeData(data?.data?.data?.nominees);
      },
    }
  );
  const queryClient = useQueryClient();

  React.useEffect(() => {
    getMeetingData.refetch();
  }, [agenda?.id, meeting.id, getMeetings.isSuccess]);
  React.useEffect(() => {
    if (getMeetings.isSuccess) {
      setNomineeData(getMeetingData.data?.data?.data?.nominees);
    }
  }, [getMeetings.isSuccess]);

  const getCapital = (shares) => {
    return Number(Math.round(shares * 500));
  };
  const getShare = (shares) => {
    return Number(shares);
  };

  const exportToCSV = () => {
    const headers = [
      "Name",
      "Name Amharic",
      "Phone",
      "Votes Capital Share",
      "Votes Share",
    ];

    const rows = nomineeData?.map((entry) => [
      entry.name,
      entry.name_amharic,
      entry.phone,
      getCapital(entry.votes_capital),
      getShare(entry.votes_share),
    ]);

    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += headers.join(",") + "\n";
    csvContent += rows.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Ranked-Nominee-List.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minWidth: "80%",
      }}
    >
      <Heading margin={"20px"} textAlign={"center"} fontSize="xl">
        Voting Result Report
      </Heading>

      <Wrap
        direction={"row"}
        gap={4}
        spacing={4}
        mx={8}
        alignItems={"center"}
        justify={"space-between"}
      >
        <WrapItem
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: 3,
          }}
        >
          <Heading size="md" my={1}>
            Select Meeting
          </Heading>

          <Select
            styles={customStyles}
            placeholder="Select Meeting"
            value={meeting}
            isDisabled={getMeetings.isLoading}
            options={getMeetings.data?.data?.data}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            isLoading={getMeetings.isLoading}
            isClearable
            onChange={(e) => {
              selectMeeting(e);
            }}
          />
        </WrapItem>

        {meeting?.agendas?.length >= 1 && (
          <WrapItem
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 3,
            }}
          >
            <Heading size="md" my={1}>
              Select Agenda
            </Heading>

            <Select
              styles={customStyles}
              placeholder="Select Agenda"
              label="Select Agenda"
              defaultValue={meeting?.agendas?.[0]}
              value={agenda}
              isDisabled={getMeetings.isLoading}
              options={meeting?.agendas}
              getOptionLabel={(option) => option.title}
              getOptionValue={(option) => option.id}
              isLoading={getMeetings.isLoading}
              isClearable
              onChange={(e) => {
                console.log("Agenda Selected", e);
                setAgenda(e);
              }}
            />
          </WrapItem>
        )}
      </Wrap>

      <MaterialTable
        ref={queryClient}
        isLoading={getMeetingData.isFetching}
        // components={{
        //   OverlayLoading: (props) => (
        //     <Progress size="md" colorScheme="pink" isIndeterminate />
        //   ),
        // }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <Text
                sx={{
                  fontSize: "md",
                  fontWeight: "bold",
                  color: "gray.500",
                  textAlign: "center",
                }}
              >
                Select Meeting To Get list of Nominee
              </Text>
            ),
          },
        }}
        style={{
          zIndex: "0",
          margin: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        options={{
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          pageSize: 10,
          rowStyle: {
            color: "#000",
            backgroundColor: "#FFF",
          },
          searchFieldStyle: {
            backgroundColor: "#FFF",
            color: "#000",
          },

          padding: "default",
          actionsColumnIndex: -1,
          exportButton: false,
        }}
        actions={[
          {
            icon: "save",
            tooltip: "Export Nominee List",
            isFreeAction: true,
            onClick: () => {
              exportToCSV();
            },
          },
        ]}
        title="Nominee List"
        columns={[
          {
            title: "Rank",
            render: (rowData) => (
              <Text>{nomineeData.indexOf(rowData) + 1}</Text>
            ),
            //make this column default sort
          },
          {
            title: "ስም",
            field: "name_amharic",
          },
          {
            title: "Name",
            field: "name",
          },
          {
            title: "Phone",
            field: "phone",
          },

          {
            title: "Votes Capital Share",
            field: "votes_capital",

            type: "numeric",
            // defaultSort: "desc",
            render: (rowData) => (
              <Text>
                {Number(
                  Math.round(rowData.votes_capital * 500)
                ).toLocaleString()}
              </Text>
            ),
          },
          {
            title: "Votes Share",
            field: "votes_share",
            type: "numeric",
            render: (rowData) => (
              <Text>{Number(rowData.votes_share).toLocaleString()}</Text>
            ),
          },
        ]}
        data={nomineeData?.sort((a, b) => b.votes_share - a.votes_share)}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
        detailPanel={(rowData) => {
          return (
            <Box>
              <MaterialTable
                localization={{
                  body: {
                    emptyDataSourceMessage: (
                      <Text
                        sx={{
                          fontSize: "md",
                          fontWeight: "bold",
                        }}
                      >
                        No Votes Yet
                      </Text>
                    ),
                  },
                }}
                style={{
                  border: "1px solid #E82481",
                  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                  borderRadius: "10px",
                  marginLeft: "3%",
                  marginRight: "3%",
                  marginBottom: "1%",
                  marginTop: "1%",
                }}
                title="List of Voters"
                columns={[
                  { title: "ስም", field: "voter.name_amharic" },
                  { title: "Name", field: "voter.name" },
                  {
                    title: "Total Capital",
                    field: "voter.total_shares",
                    render: (rowData) => (
                      <Text>
                        {Number(
                          rowData.voter.total_share * 500
                        ).toLocaleString()}
                      </Text>
                    ),
                  },
                  {
                    title: "Total Share",
                    field: "voter.total_shares",
                    render: (rowData) => (
                      <Text>
                        {Number(rowData.voter.total_share).toLocaleString()}
                      </Text>
                    ),
                  },
                ]}
                data={rowData.voters}
                options={{
                  exportAllData: true,
                  exportButton: true,
                  exportFileName: `${rowData.name} Voters`,
                }}
                //export
                actions={[
                  {
                    icon: "save",
                    tooltip: "Export Voter List",
                    isFreeAction: true,
                    onClick: () => {
                      const headers = [
                        "Name",
                        "Name Amharic",
                        "Total Capital",
                        "Total Share",
                      ];

                      const rows = rowData.voters?.map((entry) => [
                        entry.voter.name,
                        entry.voter.name_amharic,
                        Number(entry.voter.total_share * 500),
                        Number(entry.voter.total_share),
                      ]);

                      let csvContent = "data:text/csv;charset=utf-8,";
                      csvContent += headers.join(",") + "\n";
                      csvContent += rows.map((row) => row.join(",")).join("\n");

                      const encodedUri = encodeURI(csvContent);
                      const link = document.createElement("a");
                      link.setAttribute("href", encodedUri);
                      link.setAttribute(
                        "download",
                        `${rowData.name}-Voters.csv`
                      );

                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    },
                  },
                ]}
              />
            </Box>
          );
        }}
      />
    </Box>
  );
};
