import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import Login from "../Login/Login";
import AddNominee from "../storemanager/Meeting/AddNomine";
import AddNewAgenda from "../storemanager/Meeting/AddnewAgenda";
import { Agenda } from "../storemanager/Meeting/Agenda";
import { Attendance } from "../storemanager/Meeting/Attendance";
import Meeting from "../storemanager/Meeting/Meeting";
import { UpdateMeeting } from "../storemanager/Meeting/UpdateMeeting";
import CreateDelegate from "../storemanager/Proxy/CreateDelegate";
import { CreateProxy } from "../storemanager/Proxy/CreateProxy";
import { Proxy } from "../storemanager/Proxy/Proxy";
import StoreManager from "../storemanager/StoreManager";
import { AgendaVoting } from "../storemanager/Voting/AgendaVote/AgendaVoting";
import { VoteAgenda } from "../storemanager/Voting/AgendaVote/VoteAgenda";
import { Voting } from "../storemanager/Voting/NomineeVoting";
import MultiVote from "../storemanager/Voting/Vote";
import { VoteNominee } from "../storemanager/Voting/VoteNominee";
import Error from "../storemanager/components/404";
import ManageAllUsers from "../storemanager/users/ManageAllUsers";
import UtilityManagement from "../storemanager/utility/UtilsManagement";
import { Shareholders } from "../storemanager/shareholders/Shareholders";
import { VoteReport } from "../storemanager/VotingRepor/VoteReport";

const StoreManagerRoutes = () => {
  return (
    <div>
      <div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/meeting" />} />
          <Route path="/" element={<StoreManager />}>
            <Route path="/meeting" element={<Meeting />} />
            <Route path="/voting" element={<Voting />} />
            <Route path="/voting/:nomineeId" element={<VoteNominee />} />
            <Route path="/agenda-voting" element={<AgendaVoting />} />
            <Route path="/agenda-voting/:agendaId" element={<VoteAgenda />} />
            <Route path="/proxy" element={<Proxy />} />
            <Route path="/meeting/agenda/:meetingId" element={<Agenda />} />
            <Route
              path="/meeting/add-agenda/:meetingId"
              element={<AddNewAgenda />}
            />
            <Route path="/users" element={<ManageAllUsers />} />
            <Route
              path="/meeting/attendees/:meetingId"
              element={<Attendance />}
            />
            <Route path="/proxy/create-proxy" element={<CreateProxy />} />
            <Route path="/proxy/create-delegate" element={<CreateDelegate />} />
            <Route path="/meeting/:agendaId" element={<AddNominee />} />
            <Route path="/multi-vote/:meetingId" element={<MultiVote />} />
            <Route
              path="/meeting/update/:meetingId"
              element={<UpdateMeeting />}
            />
            <Route path="/utils" element={<UtilityManagement />} />
            <Route path="/shareholders" element={<Shareholders />} />
            <Route path="/report" element={<VoteReport />} />
          </Route>
          <Route path="*" element={<Error />} />
        </Routes>
      </div>
      <div />
    </div>
  );
};

export default StoreManagerRoutes;
