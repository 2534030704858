import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
// } from "@material-ui/pickers";
import Editor from "../components/Editor";

import { Formik, Field } from "formik";
import React from "react";
import * as Yup from "yup";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment/moment";
import { useNavigate, useParams, useLocation } from "react-router";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import { useToast } from "@chakra-ui/react";
import Select from "react-select";
import { colourStyles } from "./AddNomine";

const validationSchema = Yup.object({
  meeting: Yup.string()
    .required("Meeting title is required")
    .min(8, "must be at least 8 character"),
  location: Yup.string().required("Meeting location is required"),
  note: Yup.string(),
  time: Yup.date().required("Meeting time is required"),
});

export const UpdateMeeting = () => {
  const { token } = useAuth();
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();
  const params = useParams();
  const [editData, setEditData] = React.useState(null);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const updateMeetingMutation = useMutation(
    async (newData) =>
      await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}meetings/${params.meetingId}`,
        newData,

        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        toast({
          title: "Meeting created.",
          description: "Created new meeting successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        meetingData.refetch();
        navigate("/meeting");

        queryClient.invalidateQueries("meetingDataForForm");
      },
      onError: (error) => {
        toast({
          title: "Meeting creation failed.",
          description: error?.response?.data?.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      },
    },
    {
      retry: false,
    }
  );
  const meetingData = useQuery(
    "meetingDataForForm",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}meetings/${params.meetingId}`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
      onSuccess: (data) => {
        setEditData(data.data.data);
      },
    }
  );
  React.useEffect(() => {
    // queryClient.invalidateQueries("meetingDataForForm");
    meetingData.refetch();
  }, []);

  const meetingTypeData = useQuery(
    ["meetingTypeData"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}meeting-types`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  return (
    <VStack>
      <Text
        sx={{
          color: "#B51D64",
          fontWeight: "bold",
          fontSize: "25px",
          my: 5,
        }}
      >
        Update {"  "}
        <span
          style={{
            fontWeight: "bolder",
            color: "green",
            textDecoration: "underline",
          }}
        >{`${editData?.title}`}</span>
        Meeting
      </Text>
      <Flex
        sx={{
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
          justifyItems: "center",
          my: 5,
          pb: 10,
          borderBottom: "1px solid gray",
        }}
      >
        <Box>
          <Formik
            initialValues={{
              meeting: editData?.title,
              amharic_meeting: editData?.title_amharic,
              location: editData?.location,
              amharic_location: editData?.location_amharic,
              note: editData?.note,
              amharic_note: editData?.note_amharic,
              meeting_type: meetingTypeData.data?.data?.data?.find(
                (item) => item.id === editData?.meeting_type_id
              ),
              time: editData?.time,
              time_et: editData?.time_et,
            }}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => {
              updateMeetingMutation.mutate({
                title: values.meeting,
                location: values.location,
                time: values.time,
                time_et: values.time_et,
                note: values.note,
                note_amharic: values.amharic_note,
                location_amharic: values.amharic_location,
                title_amharic: values.amharic_meeting,
                meeting_type_id: values.meeting_type?.id,
              });
            }}
          >
            {(props) => {
              return (
                <>
                  <SimpleGrid
                    key={editData?.id}
                    columns={2}
                    gap={4}
                    as={"form"}
                    spacing={4}
                    align="flex-start"
                    width={"100%"}
                    onSubmit={props.handleSubmit}
                    my={8}
                  >
                    <FormControl
                      isInvalid={
                        !!props.errors.meeting && props.touched.meeting
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="Meeting Title"
                      >
                        Meeting
                      </FormLabel>
                      <Field
                        as={Input}
                        p={4}
                        placeholder="Meeting Title"
                        id="meeting"
                        name="meeting"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.meeting}
                      />
                      <FormErrorMessage>
                        {" "}
                        {props.errors.meeting}{" "}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!props.errors.amharic_meeting &&
                        props.touched.amharic_meeting
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="amharic_meeting"
                      >
                        የስብሰባ ርዕስ
                      </FormLabel>
                      <Field
                        as={Input}
                        p={4}
                        placeholder="የስብሰባ ርዕስ"
                        id="amharic_meeting"
                        name="amharic_meeting"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.amharic_meeting}
                      />
                      <FormErrorMessage>
                        {props.errors.amharic_meeting}{" "}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!props.errors.type && props.touched.type}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="type"
                      >
                        Meeting Type
                      </FormLabel>
                      <Field
                        as={Select}
                        placeholder="Select meeting type"
                        id="type"
                        name="meeting_type"
                        loading={meetingTypeData.isFetching}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        onChange={(option) => {
                          props.setFieldValue("meeting_type", option);
                        }}
                        onBlur={props.handleBlur}
                        // value={props.values.type
                        style={colourStyles}
                        options={meetingTypeData.data?.data?.data}
                      />
                      <FormErrorMessage>{props.errors.type}</FormErrorMessage>
                    </FormControl>
                    <Box />
                    <FormControl
                      isInvalid={
                        !!props.errors.location && props.touched.location
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="Location"
                      >
                        Location
                      </FormLabel>
                      <Field
                        p={4}
                        as={Input}
                        placeholder="Location"
                        id="location"
                        name="location"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.location}
                      />
                      <FormErrorMessage>
                        {props.errors.location}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!props.errors.amharic_location}>
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="Location"
                      >
                        የስብሰባ ቦታ
                      </FormLabel>
                      <Field
                        p={4}
                        as={Input}
                        placeholder="የስብሰባ ቦታ"
                        id="amharic_location"
                        name="amharic_location"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.amharic_location}
                      />
                    </FormControl>

                    <FormControl
                      isInvalid={!!props.errors.note && props.touched.note}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="note"
                      >
                        Note
                      </FormLabel>
                      {/* <Field
                        p={4}
                        as={Textarea}
                        placeholder="Enter note for meeting"
                        id="note"
                        name="note"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.note}
                        sx={{
                          resize: "vertical",
                        }}
                      /> */}
                      <Field name="note">
                        {({ field, form }) => (
                          <Editor
                            {...field}
                            {...form}
                            // editor={ClassicEditor}
                            value={props.values.note}
                            setValue={(value) => {
                              form?.setFieldValue("note", value);
                            }}
                            // onBlur={(event, editor) => {}}
                            // onFocus={(event, editor) => {}}
                          />
                        )}
                      </Field>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        !!props.errors.note && props.touched.amharic_note
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="note"
                      >
                        ማስታወሻ
                      </FormLabel>
                      {/* <Field
                        p={4}
                        as={Textarea}
                        placeholder="ማስታወሻ"
                        id="note"
                        name="amharic_note"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.amharic_note}
                      /> */}
                      <Field name="amharic_note">
                        {({ field, form }) => (
                          <Editor
                            {...field}
                            {...form}
                            value={props.values.amharic_note}
                            setValue={(value) => {
                              form?.setFieldValue("amharic_note", value);
                            }}
                          />
                        )}
                      </Field>
                    </FormControl>
                    <FormControl
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                      isInvalid={!!props.errors.time && props.touched.time}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="time"
                      >
                        Time (GC)
                      </FormLabel>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          margin="normal"
                          id="time"
                          name="time"
                          format="MM/dd/yyyy HH:mm:ss"
                          minDate={new Date()}
                          defaultValue={props.values.time}
                          value={props.values.time}
                          onChange={(value) => {
                            props.setFieldValue(
                              "time",

                              moment(value).format("YYYY-MM-DD HH:mm")
                            );
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <FormErrorMessage>{props.errors.time}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!props.errors.time_et && props.touched.time_et
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="time_et"
                      >
                        ጊዜ እና ሰዓት (EC)
                      </FormLabel>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          margin="normal"
                          id="time_et"
                          name="Time EC"
                          format="MM/dd/yyyy HH:mm:ss"
                          // minDate={new Date()}
                          defaultValue={props.values.time_et}
                          value={props.values.time_et}
                          onChange={(value) => {
                            props.setFieldValue(
                              "time_et",

                              moment(value).format("YYYY-MM-DD HH:mm:ss")
                            );
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <FormErrorMessage>{props.errors.time}</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                  <Button
                    type="submit"
                    colorScheme="teal"
                    size="lg"
                    isLoading={updateMeetingMutation.isLoading}
                    loadingText="Submitting"
                    spinnerPlacement="start"
                    sx={{
                      alignSelf: "center",
                      justifySelf: "center",
                      my: 5,
                      width: "50%",
                    }}
                    onClick={props.handleSubmit}
                  >
                    Update Meeting
                  </Button>
                </>
              );
            }}
          </Formik>
        </Box>
      </Flex>
    </VStack>
  );
};
