import { Button } from "@chakra-ui/button";
import { Box, Text, VStack } from "@chakra-ui/layout";
import { Progress, useToast } from "@chakra-ui/react";
import { Typography } from "@material-ui/core";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../utilities/hooks/auth-hook";

export const Agenda = () => {
  const { token, type } = useAuth();

  const toast = useToast();
  const [agendas, setAgendas] = React.useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();

  const params = useParams();
  const [column] = React.useState([
    {
      title: "Agenda Title",
      field: "title",
      render: (rowData) => (
        <Box>
          <Text
            sx={{
              whiteSpace: "wrap",
              wordWrap: "break-word",
              maxWidth: "150px",
            }}
          >
            {rowData.title}
          </Text>
        </Box>
      ),
    },

    {
      title: "Agenda Description",
      field: "description",

      render: (rowData) => (
        <Box>
          <Text
            sx={{
              whiteSpace: "wrap",
              wordWrap: "break-word",
              maxWidth: "700px",
            }}
            dangerouslySetInnerHTML={{ __html: rowData.description }}
          ></Text>
        </Box>
      ),
    },
    {
      title: "Is Nominee vote",

      render: (rowData) => (
        <Box>
          <Text
            sx={{
              whiteSpace: "wrap",
              wordWrap: "break-word",
              maxWidth: "700px",
            }}
          >
            {rowData.is_nominee_vote == 1 ? "Yes" : "No"}
          </Text>
        </Box>
      ),
    },
    {
      title: "Influential Can Vote",
      field: "",
      render: (rowData) => (
        <Box>
          <Text
            sx={{
              whiteSpace: "wrap",
              wordWrap: "break-word",
            }}
          >
            {rowData.exclude_influential_shareholders == 0 ? "Yes" : "No"}
          </Text>
        </Box>
      ),
    },
    {
      title: "Nominee Count",
      render: (rowData) => (
        <Box>
          <Text
            sx={{
              whiteSpace: "wrap",
              wordWrap: "break-word",
            }}
          >
            {rowData.is_nominee_vote == 1 ? rowData.nominees?.length : "-"}
          </Text>
        </Box>
      ),
    },
    {
      title: "Add Nominee",
      type: "action",

      render: (rowData) =>
        rowData.is_nominee_vote == 1 ? (
          <Button
            sx={{
              display: type === "ADMIN" ? "flex" : "none",

              color: "gray.900",
              fontSize: "1rem",
              fontWeight: "bold",
              borderColor: "green.500",
            }}
            onClick={() => {
              navigate(`/meeting/${rowData?.id}`, {
                state: { meetingId: params.meetingId },
              });
            }}
          >
            Add Nominee
          </Button>
        ) : null,
    },
    {
      title: "Delete",

      render: (rowData) => (
        <Button
          sx={{
            display: type === "ADMIN" ? "flex" : "none",

            color: "gray.900",
            fontSize: "1rem",
            fontWeight: "bold",
            borderColor: "red.500",
            backgroundColor: "red.500",
          }}
          onClick={() => {
            deleteAgenda.mutate(rowData?.id);
          }}
        >
          Delete
        </Button>
      ),
    },
  ]);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const agendaData = useQuery(
    "agendaData",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}agendas/${params?.meetingId}`,

        {
          headers,
        }
      ),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  React.useEffect(() => {
    if (agendaData?.data?.data) {
      setAgendas(agendaData?.data?.data?.data);
    }
  }, [agendaData?.data?.data]);
  const createAgenda = useMutation(
    async (data) => {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}agendas`, data, {
        headers,
      });
    },
    {
      onSuccess: () => {
        agendaData.refetch();

        toast({
          title: "Agenda created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        agendaData.refetch();
      },
    }
  );
  const deleteAgenda = useMutation(
    async (id) => {
      await axios.delete(`${process.env.REACT_APP_BACKEND_URL}agendas/${id}`, {
        headers,
      });
    },
    {
      onSuccess: () => {
        agendaData.refetch();
        toast({
          title: "Agenda deleted successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (err) => {
        toast({
          title: `${err?.response?.data?.message}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  console.log("agenda", agendas);
  return (
    <Box>
      <Text
        fontSize="2xl"
        marginLeft={6}
        fontWeight="bold"
        textAlign="center"
        mb={4}
      >
        Meeting Detail
      </Text>{" "}
      <VStack
        align={"flex-start"}
        justify={"flex-start"}
        sx={{
          margin: "2rem",
          p: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.4)",
          width: "100%",
        }}
      >
        <Typography variant="h6" gutterBottom>
          Meeting Title
        </Typography>{" "}
        <Box
          w="100%"
          display={"flex"}
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          alignContent={"flex-start"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
        >
          <Typography variant="subtitle1" align="left" gutterBottom>
            {state?.meetingTittle?.title_amharic}
          </Typography>
          <Typography variant="subtitle1" align="left" gutterBottom>
            {state?.meetingTittle?.title}
          </Typography>
        </Box>
        <Typography variant="h6" gutterBottom>
          Meeting Note
        </Typography>{" "}
        <Box
          w="100%"
          display={"flex"}
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          alignContent={"flex-start"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
        >
          <Text
            variant="subtitle1"
            align="left"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: state?.meetingTittle?.note_amharic,
            }}
          >
            {/* {state?.meetingTittle?.note_amharic} */}
          </Text>
          <Text
            variant="subtitle1"
            align="left"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: state?.meetingTittle?.note }}
          >
            {/* {state?.meetingTittle?.note} */}
          </Text>
        </Box>
        <Typography variant="h6" gutterBottom>
          Meeting Location
        </Typography>{" "}
        <Box
          display={"flex"}
          w="100%"
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          alignContent={"flex-start"}
          flexDirection={"column"}
          justifyContent={"flex-start"}
        >
          <Typography align="left" variant="subtitle1" gutterBottom>
            {state?.meetingTittle?.location_amharic}
          </Typography>
          <Typography align="left" variant="subtitle1" gutterBottom>
            {state?.meetingTittle?.location}
          </Typography>
        </Box>
        <Typography variant="h6" gutterBottom>
          Meeting Time
        </Typography>{" "}
        <Box
          display={"flex"}
          w="100%"
          p={4}
          borderWidth="1px"
          borderRadius="lg"
          alignContent={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Typography variant="subtitle1" align="left" gutterBottom>
            {"እንደ ኢትዮጵያ አቆጣጠር፡ "}
            {state?.meetingTittle?.time_et}
          </Typography>
          <Typography variant="subtitle1" align="left" gutterBottom>
            {"እንደ አውሮፓ አቆጣጠር ፡ "} {state?.meetingTittle?.time}
          </Typography>
        </Box>
      </VStack>
      <MaterialTable
        isLoading={createAgenda.isLoading}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        style={{
          margin: "2rem",
          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
        }}
        options={{
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          rowStyle: {
            color: "#000",
            backgroundColor: "#FFF",
          },
          searchFieldStyle: {
            backgroundColor: "#FFF",
            color: "#000",
          },

          padding: "default",
          actionsColumnIndex: -1,
          exportButton: true,
          exportAllData: true,
          exportFileName: "Agenda List",
        }}
        title="Agenda List"
        columns={column}
        data={agendas}
      />
    </Box>
  );
};
