import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  VStack,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import MaterialTable from "material-table";
import React from "react";
import { BsFillArrowUpRightCircleFill, BsPersonPlusFill } from "react-icons/bs";
import { useMutation, useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { useAuth } from "../../utilities/hooks/auth-hook";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object({
  name: Yup.string().nullable().required("Name is required"),
  name_amharic: Yup.string().nullable().required("Name is required"),
  phone: Yup.string()
    // .nullable()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
});

export const customStyles = {
  control: (provided, state) => ({
    ...provided,
    width: "500px",
    minHeight: "50px",
    borderColor: state.isFocused ? "#805AD5" : "#E2E8F0",
    fontSize: "18px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#805AD5",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#805AD5" : "white",
    color: state.isSelected ? "white" : "black",
  }),
};

const CreateDelegate = () => {
  const { state } = useLocation();
  const { token } = useAuth();
  const toast = useToast();
  const [open, setOpen] = React.useState(false);
  const [proxy, setProxy] = React.useState({
    meeting_id: state?.meetingId,
    stakeholder_id: "",
    description: "",
    is_stakeholder: true,
    name: "",
    name_amharic: "",
    phone: "",
    proxy_number: "",
    address: "",
  });
  const [formError, setFormError] = React.useState(false);
  const [selectedDelegators, setSelectedDelagators] = React.useState([]);
  const [selectedStackholder, setSelectedStackholder] = React.useState(null);
  const [singleDelegator, setSingleDelegator] = React.useState({
    id: "",
    name: "",
    file: null,
  });

  const fileInputRef = React.useRef(null);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  // headers for file upload
  const fileHeaders = {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const formData = new FormData();

  const handleStackholderOption = (selectedOption) => {
    setSelectedStackholder(selectedOption);
  };
  console.log("delegator => ", selectedDelegators);

  formData.append("meeting_id", proxy.meeting_id);
  // formData.append("delegator_id", JSON.stringify(selectedDelegators));
  formData.append("stakeholder_id", selectedStackholder?.id);
  formData.append("is_stakeholder", proxy.is_stakeholder);
  formData.append("description", proxy.description);
  formData.append("name", proxy.name);
  formData.append("name_amharic", proxy.name_amharic);
  formData.append("phone", proxy.phone);
  formData.append("address", proxy.address);
  formData.append("proxy_number", proxy?.proxy_number);

  selectedDelegators?.forEach((delegator, index) => {
    formData.append(`delegator_id[${delegator?.id}]`, delegator?.file);
  });

  React.useEffect(() => {
    if (proxy.is_stakeholder) {
      setProxy({ ...proxy, phone: null, name: null, address: null });
    }
  }, [proxy.is_stakeholder]);

  React.useEffect(() => {
    if (selectedDelegators && selectedStackholder) {
      const isPresent = selectedDelegators.some(
        (obj) => obj.id === selectedStackholder.id
      );
      if (isPresent) {
        toast({
          title: "Error",
          description: "Delegator and Stakeholder can't be same",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
      setFormError(isPresent);
    }
  }, [selectedDelegators, selectedStackholder]);

  const delegatorData = useQuery(
    "DelegateDatas",
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}delegators/${state?.meetingId}`,
        {
          headers,
        }
      ),

    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  const stakeholderData = useQuery(
    "stakeholderDatas",
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}stakeholders`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );

  const createDelegateMutation = useMutation(
    async (data) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}delegations`,
        data,
        {
          headers: fileHeaders,
        }
      ),
    {
      onSuccess: () => {
        setProxy({
          ...proxy,
          description: "",
          phone: "",
          name: "",
          address: "",
          is_stakeholder: true,
        });
        toast({
          title: "Delegate created successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        delegatorData.refetch();
        setSelectedDelagators([]);
      },
      onError: (error) => {
        toast({
          title: "Error",
          description: error.response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  React.useEffect(() => {
    if (singleDelegator) {
      const isPresent = selectedDelegators?.some(
        (obj) => obj.id === singleDelegator?.id
      );
      if (isPresent) {
        toast({
          title: "Error",
          description: "Delegator already added",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }, [singleDelegator]);
  const [formInitialValues, setFormInitialValues] = React.useState({
    name: "",
    phone: "",
  });
  React.useEffect(() => {
    setFormInitialValues({
      name: "",
      phone: "",
      address: "",
    });
  }, [proxy?.is_stakeholder]);
  return (
    <>
      <Center
        sx={{
          flexDirection: "column",
        }}
      >
        <Heading as="h1" size="lg" my={6}>
          Create Delegate
        </Heading>

        <Wrap
          spacing="30px"
          justify="space-evenly"
          align={["center", "center", "center", "center"]}
          width="80%"
          my={6}
          direction={"row"}
          // border="1px solid #E2E8F0"
        >
          <WrapItem as={Flex} direction="column" align="start" justify="start">
            <VStack>
              <Flex
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  my: "2rem",
                }}
              >
                <Checkbox
                  sx={{
                    color: "gray.900",
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    padding: "2rem",
                    borderColor: "green.500",
                    _focus: {
                      boxShadow: "none",
                    },
                  }}
                  defaultChecked={proxy.is_stakeholder}
                  checked={proxy.is_stakeholder}
                  onChange={(e) =>
                    setProxy({ ...proxy, is_stakeholder: e.target.checked })
                  }
                  name="radio"
                  colorScheme="green"
                  my={".5rem"}
                  size="md"
                >
                  Is Proxy Shareholder
                </Checkbox>
                {proxy.is_stakeholder ? (
                  <VStack alignItems={"flex-start"}>
                    <Text fontWeight={"bolder"} my={3}>
                      {" "}
                      Select Proxy
                    </Text>
                    <Select
                      styles={customStyles}
                      placeholder="Select proxy"
                      options={stakeholderData.data?.data?.data}
                      value={selectedStackholder}
                      getOptionLabel={(option) =>
                        `${
                          option?.serial_number
                            ? `( ${option?.serial_number})`
                            : ""
                        } ${option?.name}  `
                      }
                      getOptionValue={(option) => option.id}
                      isLoading={stakeholderData.isLoading}
                      isDisabled={stakeholderData.isLoading}
                      isClearable
                      onChange={handleStackholderOption}
                    />
                  </VStack>
                ) : (
                  <VStack alignItems={"flex-start"}>
                    <Text fontWeight={"bolder"} my={2}>
                      Create New Proxy
                    </Text>

                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={validationSchema}
                      validateOnBlur={true}
                      validateOnChange={true}
                      // onSubmit={(values, actions) => {
                      //   console.log(values.name, values.phone);
                      //   actions.setSubmitting(false);
                      // }}

                      onSubmit={(values, actions) => {
                        setProxy({
                          ...proxy,

                          name: values.name,
                          name_amharic: values.name_amharic,
                          phone: values.phone,

                          address: values.address,
                        });
                        actions.setSubmitting(false);
                      }}
                    >
                      {(props) => (
                        <Form>
                          <VStack spacing={4} align="flex-start">
                            <Field name="name">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.name && form.touched.name
                                  }
                                >
                                  <FormLabel htmlFor="name">
                                    Full Name
                                  </FormLabel>
                                  <Input
                                    {...field}
                                    p={6}
                                    placeholder="Enter full name"
                                    id="name"
                                    name="name"
                                    variant="filled"
                                    value={proxy.name}
                                    onChange={(e) => {
                                      setProxy({
                                        ...proxy,
                                        name: e.target.value,
                                      });
                                      field.onChange(e);
                                    }}
                                    onBlur={field.onBlur}
                                  />
                                  <FormErrorMessage>
                                    {form.errors.name}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="name_amharic">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.name_amharic &&
                                    form.touched.name_amharic
                                  }
                                >
                                  <FormLabel htmlFor="name_amharic">
                                    Full Name (Amharic)
                                  </FormLabel>
                                  <Input
                                    {...field}
                                    p={6}
                                    placeholder="Enter full name (Amharic)"
                                    id="name_amharic"
                                    name="name_amharic"
                                    variant="filled"
                                    value={proxy.name_amharic}
                                    onChange={(e) => {
                                      setProxy({
                                        ...proxy,
                                        name_amharic: e.target.value,
                                      });
                                      field.onChange(e);
                                    }}
                                    onBlur={field.onBlur}
                                  />
                                  <FormErrorMessage>
                                    {form.errors.name_amharic}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>

                            <Field name="phone">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.phone && form.touched.phone
                                  }
                                >
                                  <FormLabel htmlFor="phone">Phone</FormLabel>
                                  <Input
                                    {...field}
                                    p={8}
                                    placeholder="Enter phone number"
                                    id="phone"
                                    name="phone"
                                    variant="filled"
                                    value={proxy.phone}
                                    onChange={(e) => {
                                      setProxy({
                                        ...proxy,
                                        phone: e.target.value,
                                      });
                                      field.onChange(e);
                                    }}
                                    onBlur={field.onBlur}
                                  />
                                  <FormErrorMessage>
                                    {form.errors.phone}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            {/* add address field  */}
                            <Field name="address">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.address && form.touched.address
                                  }
                                >
                                  <FormLabel htmlFor="address">
                                    Address
                                  </FormLabel>
                                  <Input
                                    {...field}
                                    p={8}
                                    placeholder="Enter address"
                                    id="address"
                                    name="address"
                                    variant="filled"
                                    value={proxy.address}
                                    onChange={(e) => {
                                      setProxy({
                                        ...proxy,
                                        address: e.target.value,
                                      });
                                      field.onChange(e);
                                    }}
                                    onBlur={field.onBlur}
                                  />
                                  <FormErrorMessage>
                                    {form.errors.address}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                          </VStack>
                        </Form>
                      )}
                    </Formik>
                  </VStack>
                )}
              </Flex>
            </VStack>
          </WrapItem>
          <WrapItem
            as={Flex}
            direction="column"
            align="center"
            justify="center"
          >
            <Button
              onClick={() => {
                setOpen(true);
              }}
              colorScheme="green"
              variant="outline"
              mt={4}
              mb={4}
              width="100%"
              leftIcon={<BsPersonPlusFill />}
            >
              Add Shareholder
            </Button>
          </WrapItem>
          <VStack justify="start" width="100%" my={12}>
            <Heading size="md" mb={2} textAlign="flex-start">
              Proxy number
            </Heading>
            <Input
              sx={{
                width: "30%",
                padding: "10px",
                border: "1px solid #E2E8F0",
                my: 5,
                borderRadius: "5px",
                "&:focus": {
                  borderColor: "#805AD5",
                },
              }}
              placeholder="Enter Proxy number"
              value={proxy.proxy_number}
              onChange={(e) => {
                setProxy({ ...proxy, proxy_number: e.target.value });
              }}
            />{" "}
          </VStack>
          <VStack justify="start" width="100%" my={12}>
            <Heading size="md" mb={2} textAlign="flex-start">
              Notes
            </Heading>
            <Textarea
              sx={{
                width: "30%",
                padding: "10px",
                border: "1px solid #E2E8F0",
                my: 5,
                borderRadius: "5px",
                "&:focus": {
                  borderColor: "#805AD5",
                },
              }}
              type="text"
              placeholder="Note"
              value={proxy.description}
              onChange={(e) => {
                setProxy({ ...proxy, description: e.target.value });
              }}
            />{" "}
          </VStack>
        </Wrap>

        <Button
          type="submit"
          colorScheme="purple"
          mt={8}
          padding={5}
          disabled={formError || selectedDelegators?.length === 0}
          onClick={() => {
            createDelegateMutation.mutate(formData);
          }}
        >
          Create Delegate
        </Button>
        {selectedDelegators?.length > 0 && (
          <Box my={8} width="70%">
            <Heading size="sm" mb={2} textAlign="start">
              Selected Delegators
            </Heading>

            <MaterialTable
              columns={[
                { title: "Name", field: "name" },
                {
                  title: "File Name ",
                  field: "file.name",
                  render: (rowData) => (
                    <Text isTruncated maxW="200px">
                      {rowData.file.name}
                    </Text>
                  ),
                },
                {
                  title: "Remove",
                  field: "remove",
                  sorting: false,

                  render: (rowData) => (
                    <IconButton
                      onClick={() => {
                        setSelectedDelagators((prev) =>
                          prev.filter((item) => item?.id !== rowData?.id)
                        );
                      }}
                      colorScheme="red"
                      aria-label="Call Segun"
                      size="sm"
                      icon={<DeleteIcon />}
                    />
                  ),
                },
                {
                  title: "View File",
                  field: "action",
                  sorting: false,
                  render: (rowData) => (
                    <IconButton
                      onClick={() => {
                        window.open(
                          URL.createObjectURL(rowData.file),
                          "_blank"
                        );
                      }}
                      // colorScheme="green"
                      aria-label="Call Segun"
                      color={"green"}
                      size="sm"
                      icon={<BsFillArrowUpRightCircleFill />}
                    />
                  ),
                },
              ]}
              data={selectedDelegators}
              options={{
                search: false,
                paging: false,
                toolbar: false,

                headerStyle: {
                  backgroundColor: "#f1f1f1",
                  fontWeight: "bold",
                },
                rowStyle: {
                  border: "1px solid #E2E8F0",
                  margin: "10px",
                },

                cellStyle: {
                  border: "1px solid #E2E8F0",
                },
                maxBodyHeight: "300px",
                minBodyWidth: "100px",
              }}
              minBodyWidth={"300px"}
              title="Shareholders"
            />
          </Box>
        )}
      </Center>
      <Modal
        closeOnOverlayClick={false}
        isOpen={open}
        onClose={() => {
          setSingleDelegator(null);
          setOpen(false);
        }}
        size={"4xl"}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Shareholder </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            p={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Select
              styles={customStyles}
              placeholder="Select Shareholder"
              options={delegatorData.data?.data?.data}
              // value={singleDelegator}
              // getOptionLabel={(option) => option.name}
              getOptionLabel={(option) =>
                `${
                  option?.serial_number ? `( ${option?.serial_number})` : ""
                } ${option?.name}  `
              }
              getOptionValue={(option) => option.id}
              isLoading={delegatorData.isLoading}
              isDisabled={delegatorData.isLoading}
              // isClearable
              onChange={(e) => {
                setSingleDelegator({
                  id: e.id,
                  name: e.name,
                });
              }}
            />

            <VStack justify="start" width="100%" my={12}>
              <Box
                px={8}
                py={4}
                borderWidth={1}
                width={"80%"}
                borderColor="gray.200"
                borderRadius="md"
                textAlign="center"
                _hover={{ boxShadow: "md" }}
                id="fileInput"
                ref={fileInputRef}
                onChange={(e) => {
                  setSingleDelegator({
                    ...singleDelegator,
                    file: e.target.files[0],
                  });
                }}
              >
                <Button ref={fileInputRef} size="sm">
                  Upload Files for
                  <Input
                    type="file"
                    id="fileInput"
                    ref={fileInputRef}
                    style={{
                      opacity: 0,
                      position: "absolute",
                      top: 0,
                      left: 0,
                    }}
                    accept="application/pdf, image/*"
                  />
                </Button>
                {singleDelegator &&
                  singleDelegator.file &&
                  singleDelegator.file.name && (
                    <HStack
                      my={2}
                      width={"100%"}
                      justify={"center"}
                      align={"center"}
                      display={"flex"}
                    >
                      <>
                        <Text>{singleDelegator.file?.name}</Text>
                        <IconButton
                          icon={
                            <DeleteIcon
                              size="xs"
                              sx={{
                                color: "red.500",
                                _hover: {
                                  color: "red.700",
                                },
                              }}
                            />
                          }
                          onClick={() =>
                            setSingleDelegator({
                              ...singleDelegator,
                              file: null,
                            })
                          }
                        />
                      </>
                    </HStack>
                  )}
              </Box>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              disabled={
                !singleDelegator?.file?.name ||
                !singleDelegator?.name ||
                !singleDelegator?.id
              }
              onClick={() => {
                if (singleDelegator) {
                  setSelectedDelagators([
                    ...selectedDelegators,
                    singleDelegator,
                  ]);
                  setSingleDelegator(null);
                }
                setOpen(false);
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setSingleDelegator(null);
                setOpen(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default CreateDelegate;
