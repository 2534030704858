import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  Grid,
} from "@chakra-ui/react";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import { ArrowUpIcon } from "@chakra-ui/icons";
import { useDisclosure, useToast } from "@chakra-ui/react";

import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import { Field, Formik } from "formik";
import moment from "moment/moment";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router";
import Select from "react-select";
import * as Yup from "yup";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { colourStyles } from "../Voting/AgendaVote/AgendaVoting";
import AddMeetingType from "../utility/components/meetingType/AddMeetingType";
import MeetingCard from "./Card";
import Editor from "../components/Editor";

const validationSchema = Yup.object({
  meeting: Yup.string()
    .required("Meeting title is required")
    .min(8, "must be at least 8 character"),
  amharic_meeting: Yup.string(),
  location: Yup.string().required("Meeting location is required"),
  amharic_location: Yup.string(),
  note: Yup.string(),
  amharic_note: Yup.string(),
  time: Yup.date().required("Meeting time  (GC)is required"),
  time_et: Yup.date().required("Meeting time (EC) is required"),
  meeting_type: Yup.object().required("Meeting type is required"),
});

const Meeting = () => {
  const [meeting, setMeeting] = useState([]);
  const [showMeeting, setShowMeeting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const { token, type } = useAuth();
  const toast = useToast();
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };

  const meetingMutation = useMutation(
    async (newData) =>
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}meetings`,
        newData,

        {
          headers,
        }
      ),
    {
      onSuccess: () => {
        toast({
          title: "Meeting created.",
          description: "Created new meeting successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        meetingData.refetch();
      },
    },
    {
      retry: false,
    }
  );
  const meetingData = useQuery(
    ["meetingDataForCard"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}meetings`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  const meetingTypeData = useQuery(
    ["meetingTypeData"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}meeting-types`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  return (
    <VStack>
      <AddMeetingType isOpen={isOpen} onClose={onClose} />

      <Flex
        sx={{
          justifyContent: "space-around",
          width: "100%",
          alignItems: "center",
          justifyItems: "center",
          my: 5,
          pb: 10,
          borderBottom: "1px solid gray",
        }}
      >
        <Text
          sx={{
            color: "#B51D64",
            fontWeight: "bold",
            fontSize: "28px",
          }}
        >
          Meeting
        </Text>
        {type === "ADMIN" &&
          (showMeeting ? (
            <IconButton
              icon={<ArrowUpIcon />}
              sx={{
                backgroundColor: "#B51D64",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "bold",
                padding: "10px",
              }}
              onClick={() => setShowMeeting(!showMeeting)}
            >
              {"Create new meeting"}
            </IconButton>
          ) : (
            <Button
              sx={{
                backgroundColor: "#B51D64",
                color: "#fff",
                borderRadius: "5px",
                fontWeight: "bold",
              }}
              onClick={() => setShowMeeting(!showMeeting)}
            >
              {"Create new meeting"}
            </Button>
          ))}

        {/* <Button onClick={onOpen}>Add Meeting Type</Button> */}
      </Flex>
      {showMeeting && (
        <Box bg="white" p={6} w={"90%"}>
          <Formik
            initialValues={{
              meeting: "",
              amharic_meeting: "",
              location: "",
              amharic_location: "",
              note: "",
              amharic_note: "",
              meeting_type: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              setMeeting(values);
              meetingMutation.isLoading && actions.setSubmitting(false);
              actions.resetForm();
              showMeeting(false);
            }}
          >
            {(props) => {
              return (
                <>
                  <SimpleGrid
                    columns={2}
                    gap={4}
                    as={"form"}
                    spacing={4}
                    align="flex-start"
                    width={"100%"}
                    onSubmit={props.handleSubmit}
                    my={8}
                  >
                    <FormControl
                      isInvalid={
                        !!props.errors.meeting && props.touched.meeting
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="Meeting Title"
                      >
                        Meeting
                      </FormLabel>
                      <Field
                        as={Input}
                        p={4}
                        placeholder="Meeting Title"
                        id="meeting"
                        name="meeting"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.meeting}
                      />
                      <FormErrorMessage>
                        {" "}
                        {props.errors.meeting}{" "}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!props.errors.amharic_meeting &&
                        props.touched.amharic_meeting
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="amharic_meeting"
                      >
                        የስብሰባ ርዕስ
                      </FormLabel>
                      <Field
                        as={Input}
                        p={4}
                        placeholder="የስብሰባ ርዕስ"
                        id="amharic_meeting"
                        name="amharic_meeting"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.amharic_meeting}
                      />
                      <FormErrorMessage>
                        {props.errors.amharic_meeting}{" "}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={!!props.errors.type && props.touched.type}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="type"
                      >
                        Meeting Type
                      </FormLabel>
                      <Field
                        as={Select}
                        placeholder="Select meeting type"
                        id="type"
                        name="meeting_type"
                        loading={meetingTypeData.isFetching}
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.value}
                        onChange={(option) => {
                          props.setFieldValue("meeting_type", option);
                        }}
                        onBlur={props.handleBlur}
                        // value={props.values.type
                        style={colourStyles}
                        options={meetingTypeData.data?.data?.data}
                      />
                      <FormErrorMessage>{props.errors.type}</FormErrorMessage>
                    </FormControl>
                    <Box />
                    <FormControl
                      isInvalid={
                        !!props.errors.location && props.touched.location
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="Location"
                      >
                        Location
                      </FormLabel>
                      <Field
                        p={4}
                        as={Input}
                        placeholder="Location"
                        id="location"
                        name="location"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.location}
                      />
                      <FormErrorMessage>
                        {props.errors.location}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!props.errors.amharic_location}>
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="Location"
                      >
                        የስብሰባ ቦታ
                      </FormLabel>
                      <Field
                        p={4}
                        as={Input}
                        placeholder="የስብሰባ ቦታ"
                        id="amharic_location"
                        name="amharic_location"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.amharic_location}
                      />
                    </FormControl>

                    <FormControl
                      isInvalid={!!props.errors.note && props.touched.note}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="note"
                      >
                        Note
                      </FormLabel>
                      {/* <Field
                        p={4}
                        as={Textarea}
                        placeholder="Enter note for meeting"
                        id="note"
                        name="note"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.note}
                        sx={{
                          resize: "vertical",
                        }}
                      /> */}
                      <Field name="note">
                        {({ field, form }) => (
                          <Editor
                            {...field}
                            {...form}
                            // editor={ClassicEditor}
                            value={props.values.note}
                            setValue={(value) => {
                              form?.setFieldValue("note", value);
                            }}
                            // onBlur={(event, editor) => {}}
                            // onFocus={(event, editor) => {}}
                          />
                        )}
                      </Field>
                    </FormControl>

                    <FormControl
                      isInvalid={
                        !!props.errors.note && props.touched.amharic_note
                      }
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="note"
                      >
                        ማስታወሻ
                      </FormLabel>
                      {/* <Field
                        p={4}
                        as={Textarea}
                        placeholder="ማስታወሻ"
                        id="note"
                        name="amharic_note"
                        variant="filled"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.amharic_note}
                      /> */}
                      <Field name="amharic_note">
                        {({ field, form }) => (
                          <Editor
                            {...field}
                            {...form}
                            value={props.values.amharic_note}
                            setValue={(value) => {
                              form?.setFieldValue("amharic_note", value);
                            }}
                          />
                        )}
                      </Field>
                    </FormControl>
                    <FormControl
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                      isInvalid={!!props.errors.time && props.touched.time}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="time"
                      >
                        Time (GC)
                      </FormLabel>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          margin="normal"
                          id="time"
                          name="time"
                          format="MM/dd/yyyy HH:mm:ss"
                          minDate={new Date()}
                          defaultValue={props.values.time}
                          value={props.values.time}
                          onChange={(value) => {
                            props.setFieldValue(
                              "time",

                              moment(value).format("YYYY-MM-DD HH:mm")
                            );
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <FormErrorMessage>{props.errors.time}</FormErrorMessage>
                    </FormControl>
                    <FormControl
                      isInvalid={
                        !!props.errors.time_et && props.touched.time_et
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                      }}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#B51D64",
                        }}
                        htmlFor="time_et"
                      >
                        ጊዜ እና ሰዓት (EC)
                      </FormLabel>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          margin="normal"
                          id="time_et"
                          name="Time EC"
                          format="MM/dd/yyyy HH:mm:ss"
                          // minDate={new Date()}
                          defaultValue={props.values.time_et}
                          value={props.values.time_et}
                          onChange={(value) => {
                            props.setFieldValue(
                              "time_et",

                              moment(value).format("YYYY-MM-DD HH:mm:ss")
                            );
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      <FormErrorMessage>{props.errors.time}</FormErrorMessage>
                    </FormControl>
                  </SimpleGrid>
                  <Button
                    type="submit"
                    disabled={
                      !!props.errors.location ||
                      !!props.errors.meeting ||
                      !!props.errors.time
                    }
                    colorScheme="purple"
                    width="full"
                    isLoading={props.isSubmitting || meetingMutation.isLoading}
                    onClick={() => {
                      meetingMutation.mutate({
                        title: props.values.meeting,
                        location: props.values.location,
                        time: props.values.time,
                        note: props.values.note,
                        note_amharic: props.values.amharic_note,
                        location_amharic: props.values.amharic_location,
                        title_amharic: props.values.amharic_meeting,
                        time_et: props.values.time_et,

                        meeting_type_id: props.values.meeting_type?.id,
                      });
                    }}
                    sx={{
                      width: "20%",
                    }}
                  >
                    Create Meeting
                  </Button>
                </>
              );
            }}
          </Formik>
        </Box>
      )}
      <SimpleGrid
        columns={2}
        gap={3}
        my={8}
        width={"80%"}
        justifyContent={"space-between"}
      >
        {meetingData.isLoading && <Spinner />}
        {meetingData.isError && <Text>Something went wrong</Text>}
        {meetingData.isSuccess &&
          meetingData.data?.data?.data?.length === 0 && (
            <Text>No meeting found</Text>
          )}
        {meetingData.isSuccess &&
          meetingData.data?.data?.data?.map((meeting) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <MeetingCard
                refetch={meetingData.refetch}
                key={meeting?.id}
                agendas={meeting?.agendas}
                meetingId={meeting?.id}
                note={meeting?.note}
                today={meeting?.start_date}
                start_meeting={meeting?.start_meeting}
                meetingTittle={
                  meeting?.title?.length > 20
                    ? meeting?.title?.substring(0, 20) + "..."
                    : meeting?.title
                }
                location={
                  meeting?.location?.length > 20
                    ? meeting?.location?.substring(0, 20) + "..."
                    : meeting?.location
                }
                meetingTime={meeting?.time}
                status={meeting?.status}
                onClickAttendees={() =>
                  navigate(`/meeting/attendees/${meeting?.id}`, {
                    state: {
                      meetingTittle: meeting?.title,
                    },
                  })
                }
                onClick={() =>
                  navigate(`/meeting/add-agenda/${meeting?.id}`, {
                    state: {
                      meetingTittle: meeting?.title,
                    },
                  })
                }
                viewMeetingOnClick={() =>
                  navigate(`/meeting/agenda/${meeting?.id}`, {
                    state: {
                      meetingTittle: meeting,
                    },
                  })
                }
              />
            </Grid>
          ))}
      </SimpleGrid>
    </VStack>
  );
};

export default Meeting;
