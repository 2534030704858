import {
  Box,
  Button,
  Checkbox,
  Heading,
  SimpleGrid,
  VStack,
  Wrap,
  WrapItem,
  useToast,
  Text,
  HStack,
  Divider,
} from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { useAuth } from "../../utilities/hooks/auth-hook";

import { useLocation, useParams } from "react-router-dom";
import { customStyles } from "../Meeting/Attendance";

const MultiVote = () => {
  const [voter, setVoter] = React.useState(null);
  const [nominee, setNominee] = React.useState([]);
  const { token } = useAuth();

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const state = useLocation();
  const queryClient = useQueryClient();

  const { meetingId } = useParams();
  const getNominees = useQuery(
    `getNominees-vote`,
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}votes/nominees/${meetingId}`,
        {
          headers,
          params: {
            agenda_id: state?.state?.agendaId,
          },
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,

      retry: true,
      enabled: !!token && !!meetingId,
    }
  );

  const [isShareholder, setIsShareholder] = React.useState(true);
  const toast = useToast();
  const [currentAgenda, setCurrentAgenda] = React.useState(null);
  const getAgenda = useQuery(
    ["getAgenda", meetingId],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}agendas/${state?.state?.agendaId}/show`,
        {
          headers,
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token && !!meetingId && !!state?.state?.agendaId,
      onSuccess: (res) => {
        setCurrentAgenda(res?.data?.data);
      },
    }
  );
  const handleReset = () => {
    queryClient.invalidateQueries("getNominees-vote");
    setNominee([]);
    setVoter(null);
  };

  const voteMutation = useMutation(
    async (data) => {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}votes`, data, {
        headers,
      });
    },
    {
      onSuccess: () => {
        toast({
          title: "Vote Submitted.",
          description: "Vote submitted successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        }) && handleReset();
      },
      onError: (err) => {
        toast({
          title: `${err?.response?.data?.message ?? "An error occurred"}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      },
    }
  );
  const getAttendant = useQuery(
    ["attended-voters"],
    async () =>
      await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}attended/${meetingId}`,
        {
          headers,
          params: {
            type: "voters",
            agenda_id: state?.state?.agendaId,
          },
        }
      ),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token && !!meetingId && !!state?.state?.agendaId,
    }
  );
  const multiVoteMutation = (data) => {
    voteMutation.mutate(
      {
        nominees: data?.map((item) => item?.id),
        stakeholder_id: isShareholder ? voter?.attendable?.id : null,
        proxy_id: !isShareholder ? voter?.attendable?.id : null,
        meeting_id: meetingId,
        agenda_id: state?.state?.agendaId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries("getNominees-vote");
          getAttendant.refetch() && handleReset() && setVoter(null);
        },
      }
    );
  };

  React.useEffect(() => {
    getAgenda.refetch();
  }, [state?.state?.agendaId]);

  const [nominees, setNominees] = React.useState([]);
  React.useEffect(() => {
    if (getNominees?.isSuccess) {
      setNominees(getNominees?.data?.data?.data?.nominees);
    }
  }, [getNominees?.isSuccess, voteMutation.isSuccess]);
  return (
    <VStack>
      {" "}
      <Box
        maxW="50%"
        display={"flex"}
        p={4}
        my={4}
        borderWidth="1px"
        borderRadius="lg"
        alignContent={"start"}
        flexDirection={"column"}
        justifyContent={"start"}
        boxShadow={"lg"}
      >
        <HStack justifyContent={"start"}>
          {/* <Text variant="h3" gutterBottom>
            Meeting :
          </Text> */}
          <Text
            variant="subtitle1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: `Meeting : ${`
                ${currentAgenda?.meeting?.title ?? ""} / ${
                currentAgenda?.meeting?.title_amharic ?? ""
              }`}`,
            }}
          />
        </HStack>
        <Divider my={2} />
        <HStack justifyContent={"start"}>
          {/* <Text variant="h3" gutterBottom>
            Agenda :
          </Text> */}
          <Text
            variant="subtitle1"
            gutterBottom
            dangerouslySetInnerHTML={{
              __html: `Agenda : ${`
                ${currentAgenda?.title ?? ""} / ${
                currentAgenda?.title_amharic ?? ""
              }`}`,
            }}
          />
        </HStack>
        <Checkbox
          colorScheme="green"
          defaultChecked={state?.state?.exclude_influential_shareholders}
          disabled={true}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            py: 2,
          }}
          checked={state?.state?.exclude_influential_shareholders}
        >
          Exclude Influential Shareholders
        </Checkbox>
      </Box>{" "}
      <Wrap
        direction={"row"}
        gap={4}
        spacing={4}
        m={8}
        alignItems={"center"}
        justify={"space-around"}
        w={"100%"}
      >
        <WrapItem
          sx={{
            display: "flex",
            flexDirection: "column",
            mx: 3,
          }}
        >
          <Heading size="md" my={1}>
            Select Voter
          </Heading>

          <Select
            styles={customStyles}
            placeholder="Select Voter"
            isDisabled={getAttendant?.data?.data?.data?.length <= 0}
            options={getAttendant?.data?.data?.data}
            value={voter}
            remountOnUpdate={true}
            getOptionLabel={(option) =>
              `${
                option?.attendable?.serial_number
                  ? `( ${option?.attendable?.serial_number})`
                  : ""
              } ${option?.attendable?.name}  `
            }
            getOptionValue={(option) => option?.attendable}
            isLoading={getAttendant.isFetching}
            isClearable
            onChange={(e) => {
              setVoter(e);

              if (e?.type === "Stakeholder") {
                setIsShareholder(true);
              } else {
                setIsShareholder(false);
              }
            }}
          />
        </WrapItem>
      </Wrap>
      <Box my={8} width="70%">
        <Heading size="sm" mb={2} textAlign="start">
          List Nominees
        </Heading>
        {currentAgenda?.vote_limit ? (
          <Heading size="xs" mb={2} textAlign="start" color="gray.500">
            Vote Maximum of {currentAgenda?.vote_limit} Nominees
          </Heading>
        ) : null}

        <SimpleGrid columns={3} gap={3}>
          {nominees?.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                shadow: "lg",
                shadowColor: "green.600",
                width: "100%",
                my: 2,
                padding: "1rem",
              }}
            >
              <Checkbox
                isChecked={nominee.includes(item)}
                size="lg"
                colorScheme="green"
                defaultChecked={nominee.includes(item)}
                sx={{
                  width: "100%",
                  justifyContent: "space-around",
                  alignItems: "flex-start",
                  py: 2,
                }}
                disabled={
                  (nominee.length === currentAgenda?.vote_limit &&
                    !nominee.includes(item)) ||
                  !voter?.attendable?.id
                }
                onChange={() => {
                  setNominee((prev) =>
                    prev.includes(item)
                      ? prev.filter((i) => i !== item)
                      : [...prev, item]
                  );
                }}
                checked={nominee.includes(item)}
              >
                {/* {item.name} */}
                {`${
                  item?.stakeholder?.serial_number
                    ? `( ${item?.stakeholder?.serial_number})`
                    : ""
                } ${item?.name}  `}
              </Checkbox>
            </Box>
          ))}
        </SimpleGrid>
        <Button
          colorScheme="green"
          size="lg"
          mt={4}
          disabled={!nominee || !voter?.attendable?.id}
          isLoading={voteMutation.isLoading}
          onClick={() => {
            multiVoteMutation(nominee);
          }}
        >
          Submit Vote
        </Button>
      </Box>
    </VStack>
  );
};

export default MultiVote;
