import { Box, IconButton, Progress, Text } from "@chakra-ui/react";
import axios from "axios";
import MaterialTable from "material-table";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import { BiShowAlt } from "react-icons/bi";
import { useAuth } from "../../utilities/hooks/auth-hook";
import { Typography } from "@material-ui/core";

export const ProxyTable = () => {
  const { token } = useAuth();
  let navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  const getProxy = useQuery(
    ["delegationdataforproxy"],
    async () =>
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}proxies`, {
        headers,
      }),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      retry: true,
      enabled: !!token,
    }
  );
  console.log("getDelegation =>", getProxy?.data?.data);

  React.useEffect(() => {
    if (getProxy?.data?.data) {
      setData(getProxy?.data?.data?.data);
    }
  }, [getProxy?.data?.data]);

  const [columns, setColumns] = React.useState([
    { title: "Meeting Title", field: "title" },
    {
      title: "Meeting Location",
      field: "location",
    },
    {
      title: "Meeting Time",
      field: "time",
    },
    {
      title: "Number of Proxy",
      field: "proxies_count",
    },
    {
      title: "Total Proxy Shares",
      field: "total_proxies_share",
      render: (rowData) => (
        <Text>
          {Number(
            Math.round(rowData.total_proxies_share * 10) / 10
          ).toLocaleString()}
        </Text>
      ),
    },
    {
      title: "Add Proxy",
      field: "action",
      render: (rowData) => {
        return (
          <IconButton
            sx={{
              p: 3,
            }}
            onClick={() => {
              navigate("/proxy/create-delegate", {
                state: {
                  meetingId: rowData?.id,
                },
              });
            }}
            icon={<BsPerson />}
            bg={"#B76A1D"}
            color={"white"}
          >
            Create Delegate
          </IconButton>
        );
      },
    },
    {
      title: "View Proxies",
      field: "action",
      render: (rowData) => {
        return <IconButton icon={<BiShowAlt />} onClick={() => {}} />;
      },
    },
  ]);

  return (
    <Box w={"100%"}>
      <MaterialTable
        isLoading={getProxy.isFetching}
        components={{
          OverlayLoading: (props) => (
            <Progress size="md" colorScheme="pink" isIndeterminate />
          ),
        }}
        title="Proxy List"
        columns={columns}
        data={data}
        options={{
          actionsColumnIndex: -1,
          exportAllData: true,
          exportButton: true,
          headerStyle: {
            backgroundColor: "#E82481",
            color: "#FFF",
          },
          search: true,
        }}
        detailPanel={(rowData) => {
          return (
            <Box>
              <MaterialTable
                title="Proxy List"
                style={{
                  border: "1px solid #E2E8F0",
                  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                  borderRadius: "15px",
                  marginLeft: "5%",
                  marginRight: "5%",
                }}
                columns={[
                  { title: "Name", field: "name" },
                  { title: "phone", field: "phone" },
                  { title: "Private  Share", field: "share" },
                  {
                    title: "Delegator's Share",
                    field: "delegation_shares",
                    render: (rowData) => (
                      <Typography>
                        {Number(
                          Math.round(rowData.delegation_shares * 10) / 10
                        ).toLocaleString()}
                      </Typography>
                    ),
                  },
                  // {
                  //   title: "Share Percentage (total/attendance)",
                  //   render: (rowData) => <Typography>12% / 43%</Typography>,
                  // },
                  {
                    title: "Total Share",
                    field: "total_shares",
                    render: (rowData) => (
                      <Text>
                        {Number(
                          Math.round(rowData.total_shares * 10) / 10
                        ).toLocaleString()}
                      </Text>
                    ),
                  },
                ]}
                data={rowData.proxies}
                options={{
                  actionsColumnIndex: -1,
                  exportButton: true,
                  exportAllData: true,
                  exportFileName: `Proxy List for ${rowData.title}`,
                  search: true,
                  headerStyle: {
                    backgroundColor: "white",
                    color: "#000",
                  },
                }}
                detailPanel={(rowData) => {
                  return (
                    <Box>
                      <MaterialTable
                        title="Delegation List"
                        style={{
                          border: "1px solid #E2E8F0",
                          boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                          borderRadius: "15px",
                          marginLeft: "5%",
                          marginRight: "5%",
                        }}
                        columns={[
                          {
                            title: "Serial No",
                            field: "stakeholder.serial_number",
                          },
                          { title: "Name", field: "stakeholder.name" },
                          { title: "phone", field: "stakeholder.phone" },
                          { title: "Share", field: "stakeholder.total_share" },

                          // {
                          //   title: "Share Percentage (total/attendance)",
                          //   render: (rowData) => <Typography>12% / 43%</Typography>,
                          // },

                          {
                            title: "Is Shareholder",
                            field: "is_stakeholder",
                            render: (rowData) => {
                              return (
                                <Box>
                                  {rowData.is_stakeholder == 1 ? "Yes" : "No"}
                                </Box>
                              );
                            },
                          },
                        ]}
                        data={rowData?.delegation}
                        options={{
                          actionsColumnIndex: -1,
                          exportButton: true,
                          exportAllData: true,
                          exportFileName: `Proxy List for ${rowData.title}`,
                          search: false,
                          headerStyle: {
                            backgroundColor: "white",
                            color: "#000",
                          },
                        }}
                      />
                    </Box>
                  );
                }}
              />
            </Box>
          );
        }}
        onRowClick={(event, rowData, togglePanel) => togglePanel()}
      />
    </Box>
  );
};
